/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const country = process.env.REACT_APP_COUNTRY;

const PopUp = ({ show, handleClose, title }) => {
    const formUrl = country === "GT"
        ? "https://docs.google.com/forms/d/1wRAA7h-6Ppx5FULJ4z1aFi1XzoqIcwCRPkKTXswXt_w/viewform?edit_requested=true"
        : "https://docs.google.com/forms/d/1aa35nJyXrjNZ_z6h9KLSWz1KxGZXANMhcZMn1i6rgnM/viewform?edit_requested=true";

    const imageUrl = country === "GT"
        ? `${process.env.PUBLIC_URL}/imagenes/Popups/PopUpGT.jpg`
        : `${process.env.PUBLIC_URL}/imagenes/Popups/PopUpHN.jpg`;

    const handleImageClick = () => {
        window.open(formUrl, '_blank');
    };

    const handleVisitClick = () => {
        handleClose();
        handleImageClick();
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img
                    src={imageUrl}
                    alt="Banner"
                    style={{ cursor: 'pointer', width: '100%' }}
                    onClick={handleImageClick}
                />
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'center' }}>
                <Button
                    variant="danger"
                    onClick={handleVisitClick}
                    style={{ borderRadius: '20px', width: '200px' }}
                >
                    Ver
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PopUp;
