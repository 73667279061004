import React from 'react';
import { Container, Table, Button } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';
import { legajo } from '../../config/config';

export const Legajo = () => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/pdf/LegajoImpositivoMar2025.pdf';
    link.download = 'LegajoImpositivoMar2025.pdf';
    link.click();
  };

  return (
    <div>
      <Container>
        <h3 style={{ margin: '20px 0' }}>Agente de Recaudación</h3>
        <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
          <li>Agente de Retención Ganancias RG AFIP N° 830</li>
          <li>Agente de Recaudación IIBB CABA – Retenciones/Percepciones RG N° 352/AGIP/2022 ANEXO I</li>
          <li>Agente de Recaudación IIBB ARBA – Retenciones / Percepciones</li>
          <li>Agentes de Retención IIBB Mendoza – Retenciones</li>
          <li>Agente de Percepción IIBB Tucumán – Retenciones/Percepciones</li>
          <li>Agente de Recaudación IIBB Misiones – Retenciones / Percepciones</li>
          <li>Agentes de Retención IIBB Santiago del Estero – Retenciones</li>
          <li>Agentes de Retención IIBB Jujuy – Retenciones</li>
          <li>Agentes de Percepción IIBB Entre Rios - Percepciones</li>
          <li>Agente de Recaudación IIBB Santa Fe – Retenciones / Percepciones</li>
        </ul>
      </Container>

      <Container style={{ marginTop: '1em' }}>
        <div style={{ textAlign: 'end', marginTop: '20px', marginBottom: '20px' }}>
          <Button variant="secondary" onClick={handleDownload}>
            <FaDownload style={{ marginRight: '8px' }} />
            Descargar Legajo
          </Button>
        </div>

        <Table striped bordered hover responsive>
          <thead style={{ backgroundColor: '#6c757d' }}>
            <tr className="ingresos-brutos-header">
              <th colSpan={6} style={{ textAlign: 'center', fontSize: '32px' }}>Ingresos Brutos</th>
            </tr>
            <tr className="regimenes-header">
              <th colSpan={2}>Provincia</th>
              <th colSpan={2}>Regímenes con Exclusión</th>
              <th colSpan={2}>Vigencia</th>
            </tr>
            <tr className="column-header">
              <th>Cod</th>
              <th>Ciudad</th>
              <th>Percepciones</th>
              <th>Retenciones</th>
              <th>Desde</th>
              <th>Hasta</th>
            </tr>
          </thead>

          <tbody>
            {legajo.legajoImpositivo.map((item, index) => (
              <tr key={index}>
                <td>{item.Provincia.Cod}</td>
                <td>{item.Provincia.Ciudad}</td>
                <td>{item.Regimenes.Exclusion.Percepciones}</td>
                <td>{item.Regimenes.Exclusion.Retenciones}</td>
                <td>{item.Vigencia.Desde}</td>
                <td>{item.Vigencia.Hasta}</td>
              </tr>
            ))}
            <tr>
              <th style={{ textAlign: 'center'}} colSpan={6}>(*) Ver página 5 - (921) Santa Fé - Principio de terrotorialidad</th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center'}} colSpan={6}>(*) Ver página 17 - (914) Misiones - La Exclución solo aplica a Pagos a Cuenta IIBB - Desde 20/02/2025 hasta 19/08/2025</th>
            </tr>
          </tbody>
        </Table>

        <hr />
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Impuesto</th>
              <th>Otorgada/En Trámite</th>
              <th>Percepciones</th>
              <th>Retenciones</th>
              <th>Desde</th>
              <th>Hasta</th>
            </tr>
          </thead>
          <tbody>
            {legajo.otrosRegimenes.map((item, index) => (
              <tr key={index}>
                <td>{item.Impuesto}</td>
                <td>{item.OtorgadaEnTramite}</td>
                <td>{item.Regimen.Percepciones}</td>
                <td>{item.Regimen.Retenciones}</td>
                <td>{item.Vigencia.Desde}</td>
                <td>{item.Vigencia.Hasta}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};
