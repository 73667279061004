import * as XLSX from "xlsx";

export const generarExcel = (data, setDescargado) => {
    if (!data || data.length === 0) {
        console.log("No hay datos para descargar.");
        return;
    }

    try {
        const propiedadesOmitir = ["Alto", "Ancho", "Profundo", "Peso" , "Imagenes" , "Vendedor"]; // Aca armo un array con las propiedades que quiero ocultar
        const dataFiltrada = data.map(item => {
            let nuevoItem = {};
            Object.keys(item).forEach(key => {
                if (!propiedadesOmitir.includes(key)) {
                    nuevoItem[key] = item[key];
                }
            });
            return nuevoItem;
        });

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(dataFiltrada);
        XLSX.utils.book_append_sheet(wb, ws, "Artículos");
        XLSX.writeFile(wb, "listado-articulos.xlsx");

        setDescargado(true);
    } catch (error) {
        console.error("Error al generar el archivo Excel:", error);
    }
};
