import React from "react"
// eslint-disable-next-line react/prop-types
export const ImagenesMarcas =({id})=>{
    const country = process.env.REACT_APP_COUNTRY;

    /* URLS DE IMAGENES */
  let CN = process.env.PUBLIC_URL + "/assets/Marcas/CN.webp"
  let COR = process.env.PUBLIC_URL + "/assets/Marcas/CORSAIR.webp"
  let GRA = process.env.PUBLIC_URL + "/assets/Marcas/GS.webp"
  let HUA = process.env.PUBLIC_URL + "/assets/Marcas/HUAWEI.webp"
  let MIK = process.env.PUBLIC_URL + "/assets/Marcas/MIKROTIK.webp"
  let PAT = process.env.PUBLIC_URL + "/assets/Marcas/PATRIOT.webp"
  let QNP = process.env.PUBLIC_URL + "/assets/Marcas/QNAP.webp"
  let SMS = process.env.PUBLIC_URL + "/assets/Marcas/SAMSUNG.webp"
  let TAR = process.env.PUBLIC_URL + "/assets/Marcas/TARGUS.webp"
  let TPL = process.env.PUBLIC_URL + "/assets/Marcas/TPLINK.webp"
  let UBI = process.env.PUBLIC_URL + "/assets/Marcas/UBIQUITI2.webp"
  let YEA = process.env.PUBLIC_URL + "/assets/Marcas/YEALINK.webp"
  let ADA = process.env.PUBLIC_URL + "/assets/Marcas/ADATA.webp"
  let AMD = process.env.PUBLIC_URL + "/assets/Marcas/AMD.webp"
  let APC = process.env.PUBLIC_URL + "/assets/Marcas/APC.webp"
  let APP = process.env.PUBLIC_URL + "/assets/Marcas/APPLE.webp"
  let ARU = process.env.PUBLIC_URL + "/assets/Marcas/ARUBA.webp"
  let ASR = process.env.PUBLIC_URL + "/assets/Marcas/ASROCK.webp"
  let CLM = process.env.PUBLIC_URL + "/assets/Marcas/COOLERMASTER.webp"
  let DAH = process.env.PUBLIC_URL + "/assets/Marcas/DAHUA.webp"
  let EVG = process.env.PUBLIC_URL + "/assets/Marcas/EVGA.webp"
  let GAR = process.env.PUBLIC_URL + "/assets/Marcas/GARMIN.webp"
  let GIG = process.env.PUBLIC_URL + "/assets/Marcas/GIGABYTE.webp"
  let GOP = process.env.PUBLIC_URL + "/assets/Marcas/GOPRO.webp"
  let JAB = process.env.PUBLIC_URL + "/assets/Marcas/JABRA.webp"
  let KOD = process.env.PUBLIC_URL + "/assets/Marcas/KODAK.webp"
  let LEN = process.env.PUBLIC_URL + "/assets/Marcas/LENOVO.webp"
  let MSI = process.env.PUBLIC_URL + "/assets/Marcas/MSI.webp"
  let NSX = process.env.PUBLIC_URL + "/assets/Marcas/NSX.webp"
  let PWC = process.env.PUBLIC_URL + "/assets/Marcas/POWERCOLOR.webp"
  let WCM = process.env.PUBLIC_URL + "/assets/Marcas/WACOM.webp"
  let WAC = process.env.PUBLIC_URL + "/assets/Marcas/WACOM.webp" 
  let ASU = process.env.PUBLIC_URL + "/assets/Marcas/ASUS.webp" 
  let HPI = process.env.PUBLIC_URL + "/assets/Marcas/HP.webp" 
  let KIN = process.env.PUBLIC_URL + "/assets/Marcas/KINGSTON.webp" 
  let PA = process.env.PUBLIC_URL + "/assets/Marcas/PANDUIT.webp" 
  let DEC = process.env.PUBLIC_URL + "/assets/Marcas/DELL.webp" 
  let CIS = process.env.PUBLIC_URL + "/assets/Marcas/CISCO.webp" 
  let CID = process.env.PUBLIC_URL + "/assets/Marcas/CISCO2.webp" 
  let LG = process.env.PUBLIC_URL + "/assets/Marcas/LG.webp" 
  let ARD = process.env.PUBLIC_URL + "/assets/Marcas/Aruba.png" 
  let INT = process.env.PUBLIC_URL + "/assets/Marcas/INTEL.webp" 
  let CDA = process.env.PUBLIC_URL + "/assets/Marcas/CDATA.jpg" 
  let BRO = process.env.PUBLIC_URL + "/assets/Marcas/BROTHER.webp" 
  let LEX = process.env.PUBLIC_URL + "/assets/Marcas/LEXAR.webp" 
  let MER = process.env.PUBLIC_URL + "/assets/Marcas/MERCUSYS.webp" 
  let HPS = process.env.PUBLIC_URL + "/assets/Marcas/hps.png" 
  let AMA = process.env.PUBLIC_URL + "/assets/Marcas/AMAZFIT.jpg"
  let COO = process.env.PUBLIC_URL + "/assets/Marcas/cooler.jpg" 
  let EPC = process.env.PUBLIC_URL + "/assets/Marcas/epson.jpg" 
  let EPS = process.env.PUBLIC_URL + "/assets/Marcas/epsonescanners.jpg" 
  let FUR = process.env.PUBLIC_URL + "/assets/Marcas/furukawa.jpg" 
  let GAB = process.env.PUBLIC_URL + "/assets/Marcas/gabitel.jpg" 
  let HIL = process.env.PUBLIC_URL + "/assets/Marcas/hikvision.jpg" 
  let JBL = process.env.PUBLIC_URL + "/assets/Marcas/jbl.jpg" 
  let KING = process.env.PUBLIC_URL + "/assets/Marcas/kingston.jpg" 
  let LGA = process.env.PUBLIC_URL + "/assets/Marcas/lgsonido.jpg" 
  let LGE = process.env.PUBLIC_URL + "/assets/Marcas/lgElectro.jpg" 
  let LGTV = process.env.PUBLIC_URL + "/assets/Marcas/LGtv.jpg" 
  let MICR = process.env.PUBLIC_URL + "/assets/Marcas/microsoft.jpg" 
  let MTV = process.env.PUBLIC_URL + "/assets/Marcas/motorola.jpg" 
  let NOC = process.env.PUBLIC_URL + "/assets/Marcas/noblexcel.jpg" 
  let NSP = process.env.PUBLIC_URL + "/assets/Marcas/nsxgamer.jpg" 
  let QBO = process.env.PUBLIC_URL + "/assets/Marcas/qbox.jpg" 
  let WDG = process.env.PUBLIC_URL + "/assets/Marcas/wd.jpg" 
  let XIA = process.env.PUBLIC_URL + "/assets/Marcas/xiaomi.jpg" 
  let ZEB = process.env.PUBLIC_URL + "/assets/Marcas/zebra.jpg" 
  let SVSAM = process.env.PUBLIC_URL + "/assets/Marcas/SVSAM.png" 
  let LES = process.env.PUBLIC_URL + "/assets/Marcas/lenServer.png" 
  let PLY = process.env.PUBLIC_URL + "/assets/Marcas/banner_poly.webp" 

  

  return(
      <img 
          src={
              country === 'SV' && (id === "SDS" || id === "SMO") ? `${SVSAM}` :
              id === "LES" ? `${LES}` :
              id === "PLY" ? `${PLY}` :
              id === "005" || id === "AMD" ? `${AMD}` :
              id === "006" ? `${APC}` :
              id === "008" || id === "APP" ? `${APP}` :
              id === "038" ? `${LG}` :
              id === "040" || id === "BRO" ? `${BRO}` :
              id === "044" || id === "INT" ? `${INT}` :
              id === "048" ? `${KIN}` :
              id === "065" ? `${MIK}` :
              id === "081" || id === "SDS" || id === "SMO" || id === "SAU" || id === "SAB" || id === "SAC" || id === "SPI" || id === "SMT" ? `${SMS}` :
              id === "159" || id === "ASU" ? `${ASU}` :
              id === "187" | id === "CIS" ? `${CIS}` :
              id === "221" || id === "TAR" ? `${TAR}` :
              id === "265" ? `${CID}` :
              id === "270" || id === "MSI" ? `${MSI}` :
              id === "ADA" || id === "ADT" ? `${ADA}` :
              id === "ASR" || id === "ASK" ? `${ASR}` :
              id === "CDA" ? `${CDA}` :
              id === "AMA" ? `${AMA}` :
              id === "CLM" ? `${CLM}` :
              id === "CN"  ? `${CN}`  :
              id === "COR" ? `${COR}` :
              id === "DAH" || id === "DAU" ? `${DAH}` :
              id === "DEC" ? `${DEC}` :
              id === "DEE" ? `${DEC}` :
              id === "EVG" ? `${EVG}` :
              id === "GAR" ? `${GAR}` :
              id === "COO" ? `${COO}` :
              id === "EPC" ? `${EPC}` :
              id === "EPS" ? `${EPS}` :
              id === "FUR" ? `${FUR}` :
              id === "GAB" ? `${GAB}` :
              id === "HIL" ? `${HIL}` :
              id === "JBL" ? `${JBL}` :
              id === "KIN" ? `${KING}` :
              id === "LGA" ? `${LGA}` :
              id === "LGE" ? `${LGE}` :
              id === "LG" ? `${LGTV}` :
              id === "064" ? `${MICR}` :
              id === "MTV" ? `${MTV}` :
              id === "NOC" ? `${NOC}` :
              id === "NSP" ? `${NSP}` :
              id === "QBO" ? `${QBO}` :
              id === "109" ? `${WDG}` :
              id === "XIA" ? `${XIA}` :
              id === "ZEB" ? `${ZEB}` :
              id === "GIG" ? `${GIG}` :
              id === "GOP" || id === "GPR" ? `${GOP}` :
              id === "GRA" ? `${GRA}` :
              id === "HPC" || id === "HPL" || id === "HSS" || id === "HPI" || id === "HPP" || id === "TSS" ? `${HPI}` :
              id === "HPE" ? `${HPI}` :
              id === "HPS" ? `${HPS}` :
              id === "HPN" || id === "ION" || id === "ARW" || id === "ARU" ? `${ARD}` :
              id === "ION" || id === "ARW" || id === "ARU" ? `${ARU}` :
              id === "HUA" ? `${HUA}` :
              id === "JAB" ? `${JAB}` :
              id === "KOD" || id === "KOS" ? `${KOD}` :
              id === "LEN" || id === "LEC" ? `${LEN}` :
              id === "LEX" ? `${LEX}` :
              id === "MER" ? `${MER}` :
              id === "NSX" || id === "NSN" || id === "NSG" ? `${NSX}` :
              id === "PA" || id === "PAN" ? `${PA}` :
              id === "PAA" || id === "PAT" || id === "PTO" || id === "PTT" || id === "PTR" ? `${PAT}` :
              id === "PWC" ? `${PWC}` :
              id === "QNP" ? `${QNP}` :
              id === "TAR" || id === "221" ? `${TAR}` :
              id === "TPL" ? `${TPL}` :
              id === "UBI" ? `${UBI}` :
              id === "WAC" ? `${WAC}` :
              id === "WCM" || id === "WAC" ? `${WCM}` :
              id === "YEA" ? `${YEA}` :     
              ""
          } 
          alt="" 
          className='imagenesBody'
          loading="lazy"
          width='100%'
          height='auto'
      />
  )
}