/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Pagination, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import { BsFillGrid1X2Fill } from 'react-icons/bs';
import { FaThList } from 'react-icons/fa';
import { authContext } from '../Context/AuthContext';
import { globalContext } from '../Context/GlobalContext';
import { SpinnerMarcas } from '../helpers/SpinnerMarcas';
import TablesCategorias from './TablesCategorias';
import CardCategorias from './CardCategorias';
import { useTranslation } from 'react-i18next';
import { MdDownload } from "react-icons/md";
import { generarExcel } from '../../utilities/helpers/generadorExcel';

const apiProductos = process.env.REACT_APP_API_PRODUCTOS;

export const ApiCategorias = () => {
    const { codigo } = useParams();
    const [articulosPorCategoria, setArticulosPorCategoria] = useState(null);
    const [load, setLoad] = useState(false);
    const { colorTheme, handleDisplayGrid, handleDisplayList, grid } = useContext(globalContext);
    const [isMobile, setIsMobile] = useState(false);
    const [largoTotal, setLargoTotal] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const { token } = useContext(authContext);
    const elementosPorPagina = 7;
    const cantidadDePaginas = Math.ceil(largoTotal / elementosPorPagina);
    const navigate = useNavigate();
    const [t] = useTranslation("global");
    const [showOnlyStock, setShowOnlyStock] = useState(false);
    const country = process.env.REACT_APP_COUNTRY;
    const [descargado, setDescargado] = useState(false);

    // Categorías 
    useEffect(() => {
        const isMobileOnLoad = window.innerWidth < 670;
        setIsMobile(isMobileOnLoad);
        const handleResize = () => {
            setIsMobile(window.innerWidth < 670);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const obtenerDatos = async () => {
            try {
                setLoad(false);
                let response;

                if ((token)) {
                    const stockFilter = showOnlyStock ? 1 : 0;

                    response = await axios.get(
                        `${apiProductos}/info/categoria/${codigo}?Stock=${stockFilter}&limit=${elementosPorPagina}&offset=${elementosPorPagina * (currentPage - 1)}`, {
                        headers: { 'Authorization': 'Bearer ' + token }
                    }
                    );

                } else {
                    response = await axios.get(
                        `${apiProductos}/info/categoria/${codigo}?limit=${elementosPorPagina}&offset=${elementosPorPagina * (currentPage - 1)}`, {
                        headers: { 'Authorization': 'Bearer ' + token }
                    }
                    );
                }

                setArticulosPorCategoria(response.data.articulos);
                localStorage.setItem('artXCategoria', JSON.stringify(response.data.articulos));
                setLargoTotal(response.data.length);

                setTimeout(() => {
                    setLoad(true);
                }, 1000);
            } catch (error) {
                console.error('Error al obtener las categorías:', error);
                setArticulosPorCategoria(null);
                setLoad(true);
            }
        };

        obtenerDatos();
    }, [codigo, currentPage, showOnlyStock]);

    // Mostrar productos basados en las categorías almacenadas
    useEffect(() => {
        const storedCategories = localStorage.getItem('artXCategoria');
        if (storedCategories) {
            setArticulosPorCategoria(JSON.parse(storedCategories));
            setLoad(true);
        }
    }, []);

    const paginas = [];
    function renderPagination() {
        for (let i = 1; i <= cantidadDePaginas; i++) {
            paginas.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
                    {i}
                </Pagination.Item>
            );
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    useEffect(() => {
        if (articulosPorCategoria && articulosPorCategoria.length > 0) {
            setDescargado(false);
        }
    }, [articulosPorCategoria]);

    return (
        <div className={`api-map-container bg-${colorTheme}`}>
            <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"} alt="Imagen marcas" className='imagenesBody' loading='lazy'></img>
            {!load ? (
                <SpinnerMarcas />
            ) : articulosPorCategoria && articulosPorCategoria.length > 0 ? (
                <Container fluid className='py-5' style={{ width: "100%" }}>
                    {!isMobile && (
                        <div className="display-select-container">
                            <Col className={`d-flex justify-content-end text-${colorTheme === 'dark' ? 'light' : 'dark'}`}>
                                <div className="row">
                                    <div className="span6" style={{ width: "115px" }}>
                                        <div className="mycontent-left text-center">
                                            <h6 className="text-uppercase">{t("producto.ver-como")}</h6>
                                            <div className="d-flex flex-col justify-content-around gap-1">
                                                <div
                                                    className={`d-flex flex-row align-items-center svgGrilla ${!grid ? 'activeView' : ''}`}
                                                    onClick={handleDisplayList}>
                                                    <FaThList size={"1.5em"} />
                                                </div>
                                                <div
                                                    className={`d-flex flex-row align-items-center svgGrilla ${grid ? 'activeView' : ''}`}
                                                    onClick={handleDisplayGrid}>
                                                    <BsFillGrid1X2Fill size={"1.5em"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    )}

                    <div style={{ display: !isMobile && 'flex', justifyContent: 'space-between' }}>
                        {(country === "USA" || (country === "ARG" && token)) && (
                            <div className="display-select-container" style={{ flex: 1, textAlign: 'center' }}>
                                <h3 style={{ marginBottom: '20px', color: colorTheme === 'dark' ? '#fff' : '#000' }}>
                                    Ver:
                                </h3>
                                <Col
                                    className={`d-flex justify-content-center text-${colorTheme === 'dark' ? 'light' : 'dark'}`}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                                        {/* Opción: Con Stock */}
                                        <p
                                            style={{
                                                cursor: 'pointer',
                                                color: showOnlyStock ? 'red' : (colorTheme === 'dark' ? '#fff' : '#6c757d'),
                                                fontSize: '18px',
                                                fontWeight: 'bold',
                                                textAlign: 'left',
                                                transition: 'color 0.3s, text-decoration 0.3s',
                                                textDecoration: showOnlyStock ? 'underline' : 'none',
                                            }}
                                            onClick={() => setShowOnlyStock(true)}
                                            onMouseEnter={(e) => {
                                                if (!showOnlyStock) {
                                                    e.currentTarget.style.color = '#6c757d';
                                                    e.currentTarget.style.textDecoration = 'underline';
                                                }
                                            }}
                                            onMouseLeave={(e) => {
                                                if (!showOnlyStock) {
                                                    e.currentTarget.style.color = '#6c757d';
                                                    e.currentTarget.style.textDecoration = 'none';
                                                }
                                            }}
                                        >
                                            Productos solo con stock
                                        </p>

                                        {/* Opción: Sin Stock */}
                                        {(country === "ARG" || country === "USA") && (
                                            <p
                                                style={{
                                                    cursor: 'pointer',
                                                    color: !showOnlyStock ? 'red' : (colorTheme === 'dark' ? '#fff' : '#6c757d'),
                                                    fontSize: '18px',
                                                    textAlign: 'left',
                                                    fontWeight: 'bold',
                                                    transition: 'color 0.3s, text-decoration 0.3s',
                                                    textDecoration: !showOnlyStock ? 'underline' : 'none',
                                                }}
                                                onClick={() => setShowOnlyStock(false)}
                                                onMouseEnter={(e) => {
                                                    if (showOnlyStock) {
                                                        e.currentTarget.style.color = '#6c757d';
                                                        e.currentTarget.style.textDecoration = 'underline';
                                                    }
                                                }}
                                                onMouseLeave={(e) => {
                                                    if (showOnlyStock) {
                                                        e.currentTarget.style.color = '#6c757d';
                                                        e.currentTarget.style.textDecoration = 'none';
                                                    }
                                                }}
                                            >
                                                Todos los productos
                                            </p>
                                        )}
                                    </div>
                                </Col>

                                <hr style={{ width: '50%', margin: '0 auto', border: 'none', height: '2px', backgroundColor: '#000', marginTop: '0.5em', marginBottom: '1em' }} />
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="outline-success"
                                        className="rounded-pill px-4 py-2 text-center"
                                        onClick={() => generarExcel(articulosPorCategoria, setDescargado)}
                                        style={{ 
                                            display: "flex", 
                                            flexDirection: "column", 
                                            alignItems: "center", 
                                            opacity: showOnlyStock ? 1 : 0, // Opacidad reducida cuando está deshabilitado
                                            cursor: showOnlyStock ? "pointer" : "not-allowed", // Cursor no permitido cuando está deshabilitado
                                        }}
                                        disabled={!showOnlyStock} // Deshabilitar el botón cuando showOnlyStock es false
                                    >
                                        {showOnlyStock ? "Descargar planilla con Stock" : "Descargar planilla completa"}
                                        <MdDownload size={25} style={{ marginTop: "4px" }} />
                                    </Button>
                                </div>
                            </div>
                        )}

                        <div style={{ flex: 4 }}>
                            <Row className='mt-2' style={{ justifyContent: "center" }}>
                                <Col xs={12} md={12} sm={6} xl={9} lg={12} style={{ display: "flex", justifyContent: "center" }}>
                                    {isMobile ? (
                                        <div className="taleShadow bg-light">
                                            <CardCategorias articulosPorCategoria={articulosPorCategoria} />
                                            <Pagination>{paginas}</Pagination>
                                            <div>{t("producto.pagina")} {currentPage} {t("producto.de")} {cantidadDePaginas}</div>
                                            {renderPagination()}
                                        </div>
                                    ) : grid ? (
                                        <div className="taleShadow bg-light">
                                            <CardCategorias articulosPorCategoria={articulosPorCategoria} />
                                            <Pagination>{paginas}</Pagination>
                                            <div>{t("producto.pagina")} {currentPage} {t("producto.de")} {cantidadDePaginas}</div>
                                            {renderPagination()}
                                        </div>
                                    ) : (
                                        <div className="taleShadow bg-light">
                                            <TablesCategorias articulosPorCategoria={articulosPorCategoria} header={["", ""]} />
                                            <Pagination>{paginas}</Pagination>
                                            <div>{t("producto.pagina")} {currentPage} {t("producto.de")} {cantidadDePaginas}</div>
                                            {renderPagination()}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            ) : (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', padding: '20px 0' }}>
                    <p style={{ textAlign: 'center', fontSize: '24px', padding: '20px 0' }}>{t("producto.no-existe-categoria")}.</p>
                    <button
                        style={{ alignSelf: 'center', background: '#ef233c' }}
                        onClick={() => navigate('/')}
                    >
                        {t("producto.volver-inicio")}
                    </button>
                </div>
            )}
        </div>
    );
};