import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getProductosPorSearchInputService } from '../../../services/Buscador';
import { Container, Row, Col } from 'react-bootstrap';
import FiltroMarcas from '../Marcas/FiltroMarcas';
import FiltroCategorias from '../Categorias/FiltroCategorias';
import ProductosFiltradosContent from './ProductosFiltradosContent';
import { getMarcasFromLocalStorage } from '../../../utilities/hooks/Marcas/getMarcasFromLocalStorage';
import { useGetCategorias } from '../../../utilities/hooks/Categorias/useGetCategorias';

const ProductosFiltrados = () => {
    const { query } = useParams();
    const navigate = useNavigate();
    const [productos, setProductos] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const productosPorPagina = 7;
    //const country = process.env.REACT_APP_COUNTRY;

    const { categorias: categoriasData } = useGetCategorias();

    useEffect(() => {
        const fetchProductosFiltrados = async () => {
            setLoading(true);
            try {

                // Saco la condicion para que se muestre siempre el llamado a searchinputservice 
                // const [productosFiltrados, productosPorSKU] = await Promise.all([
                //     country !== "ARG" ? getProductosPorBusquedaService(query) : [],
                //     query.length > 3
                //         ? (country === "ARG" ? getProductosPorSearchInputService(query) : getProductosPorSKUService(query))
                //         : []
                // ]);
                const productosFiltrados = await getProductosPorSearchInputService(query);


                // Combinar productos filtrados con productos encontrados por SKU
                const productosUnicos = [
                    ...productosFiltrados,
                    // ...(Array.isArray(productosPorSKU) ? productosPorSKU : [productosPorSKU])
                ].reduce((acc, producto) => {
                    if (!acc.find(p => p.Alias === producto.Alias)) acc.push(producto);
                    return acc;
                }, []);

                setProductos(productosUnicos);

                const allMarcas = getMarcasFromLocalStorage();
                const marcasCoincidentes = allMarcas.filter(marca =>
                    marca.Descripcion.toLowerCase().includes(query.toLowerCase())
                );
                setMarcas(marcasCoincidentes);

                const totalProductos = productosUnicos.length + marcasCoincidentes.length;
                setTotalPages(Math.ceil(totalProductos / productosPorPagina));

                const categoriasCoincidentes = categoriasData.filter(categoria =>
                    categoria.Descripcion.toLowerCase().includes(query.toLowerCase())
                );
                setCategorias(categoriasCoincidentes);
            } catch (error) {
                setError('No se pudieron obtener los productos.');
            } finally {
                setLoading(false);
            }
        };

        fetchProductosFiltrados();
    }, [query, categoriasData]);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const shouldShowFilters = marcas.length > 0 || categorias.length > 0;

    return (
        <>
            <img src={process.env.PUBLIC_URL + "/banner/BUSQUEDA2.png"} alt="Imagen capacitaciones" className='imagenesBody' loading='lazy' />
            <Container style={{ backgroundColor: 'white', marginTop: '1em' }}>
                <Row>
                    {shouldShowFilters ? (
                        <>
                            <Col md={3}>
                                <FiltroMarcas marcas={marcas} query={query} />
                                <FiltroCategorias categorias={categorias} query={query} />
                            </Col>
                            <Col md={9}>
                                <ProductosFiltradosContent
                                    productos={productos.slice((currentPage - 1) * productosPorPagina, currentPage * productosPorPagina)}
                                    loading={loading}
                                    error={error}
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    handlePageChange={handlePageChange}
                                    handleCardClick={(sku) => navigate(`/detalle?sku=${sku}`)}
                                />
                            </Col>
                        </>
                    ) : (
                        <Col md={12}>
                            <ProductosFiltradosContent
                                productos={productos.slice((currentPage - 1) * productosPorPagina, currentPage * productosPorPagina)}
                                loading={loading}
                                error={error}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                                handleCardClick={(sku) => navigate(`/detalle?sku=${sku}`)}
                            />
                        </Col>
                    )}
                </Row>
            </Container>
        </>
    );
};

export default ProductosFiltrados;
