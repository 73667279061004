/* eslint-disable react/prop-types */
import React from 'react';
import { Form, Button } from 'react-bootstrap';

const DirectionForm = ({ direccionForm, handleDireccionChange, handleSubmitDireccion, provincias }) => {
    return (
        <form onSubmit={handleSubmitDireccion}>
            <h4 style={{ fontWeight: "bold", marginBottom: "20px" }}>
                Colocar domicilio de entrega:
            </h4>
            <div style={{ marginBottom: "15px" }}>
                <label style={{ fontWeight: "bold", color: "#475569" }}>Calle y altura:</label>
                <input
                    type="text"
                    name="direccion"
                    value={direccionForm.direccion}
                    onChange={handleDireccionChange}
                    style={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid #dcdcdc",
                        borderRadius: "4px",
                    }}
                />
            </div>
            <div style={{ marginBottom: "15px" }}>
                <label style={{ fontWeight: "bold", color: "#475569" }}>Código Postal:</label>
                <input
                    type="text"
                    name="codigoPostal"
                    value={direccionForm.codigoPostal}
                    onChange={handleDireccionChange}
                    style={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid #dcdcdc",
                        borderRadius: "4px",
                    }}
                />
            </div>
            <div style={{ marginBottom: "15px" }}>
                <label style={{ fontWeight: "bold", color: "#475569" }}>Localidad:</label>
                <input
                    type="text"
                    name="localidad"
                    value={direccionForm.localidad}
                    onChange={handleDireccionChange}
                    style={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid #dcdcdc",
                        borderRadius: "4px",
                    }}
                />
            </div>

            <Form.Group controlId="provincia">
                <Form.Label>
                    Provincia:
                </Form.Label>
                <Form.Control
                    as="select"
                    name="provincia"
                    value={direccionForm.provincia.Descripcion}
                    onChange={handleDireccionChange}
                >
                    <option value="">Seleccione...</option>
                    {provincias.map((provincia) => (
                        <option key={provincia.Codigo} value={provincia.Descripcion} data-codigo={provincia.Codigo}>
                            {provincia.Descripcion}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>

            <div style={{ marginBottom: "15px" }}>
                <label style={{ fontWeight: "bold", color: "#475569" }}>País:</label>
                <input
                    type="text"
                    name="pais"
                    value={direccionForm.pais}
                    onChange={handleDireccionChange}
                    style={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid #dcdcdc",
                        borderRadius: "4px",
                    }}
                />
            </div>
            <Button
                type="submit"
                variant="outline-success"
                style={{
                    width: '100%',
                    display: 'block',
                    margin: '0 auto',
                    backgroundColor: 'transparent',
                    color: '#4CAF50',
                    border: '2px solid #4CAF50',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    marginTop: '20px',
                    transition: 'all 0.3s ease-in-out',
                    marginBottom: '15px',
                }}
            >
                Actualizar domicilio de envío
            </Button>
        </form>
    );
};

export default DirectionForm;
