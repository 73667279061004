import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown, Container, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { useTranslation } from "react-i18next";
import { useFetchMarcas } from "../../utilities/hooks/useFetchMarcas.jsx";
import { useCategoryNavigation } from "../../utilities/hooks/Categorias/useCategoryNavigation.jsx";
import { navbarData, politicasAnticorrupcion } from "../../config/config.js";
import { useGetCategorias } from "../../utilities/hooks/Categorias/useGetCategorias.jsx";

const apiProductos = process.env.REACT_APP_API_PRODUCTOS;

export const Barra1 = () => {
  const country = process.env.REACT_APP_COUNTRY;
  const [t] = useTranslation("global")
  const { marcasMapeadas } = useFetchMarcas(apiProductos);
  const { categorias } = useGetCategorias();
  const { handleMouseEnter, handleMouseLeave, handleClick, renderSubcategories, hoveredCategories } = useCategoryNavigation();
  const [showDropdown, setShowDropdown] = useState(false)
  const [codPath, setCodPath] = useState('');
  const [manPath, setManPath] = useState('');
  const [procPath, setProcPath] = useState('');


  useEffect(() => {
    // const country = process.env.REACT_APP_COUNTRY;
    setCodPath(politicasAnticorrupcion[country]?.cod || '');
    setManPath(politicasAnticorrupcion[country]?.man || '');
    setProcPath(politicasAnticorrupcion[country]?.proc || '');
  }, []);


  return (
    <div className="bordeBarraNav">
      <Navbar
        expand="lg"
        className={`lightNav`}
      >
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="mx-auto" style={{ height: "100%" }}>
              {country !== "JM" && country !== "TT" && country !== "PR" &&
                <NavDropdown
                  title={t("navbar.tienda")}
                  style={{ textTransform: "uppercase" }}
                  id="navbarScrollingDropdown"
                >

                  {/* Dropdown de marcas */}

                  {country !== "JM" && country !== "TT" && country !== "PR" &&
                    <NavDropdown
                      title={t("navbar.marcas")}
                      style={{ textTransform: "uppercase" }}
                      id="navbarScrollingDropdownMarcas"
                      onClick={() => setShowDropdown(false)}
                      drop="end"
                    >
                      {/* Contenedor para el scroll dentro del dropdown */}
                      <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                        {marcasMapeadas.map((link) => {
                          return (
                            <NavDropdown.Item key={link.Codigo}>
                              <Link
                                rel="prefetch"
                                to={`/marcas/${link.Codigo}`}
                                className="dropdown-item mod"
                              >
                                {link.Descripcion}
                              </Link>
                            </NavDropdown.Item>
                          );
                        })}
                      </div>
                    </NavDropdown>
                  }

                  {/* Dropwown de categorias */}

                  {country !== "JM" && country !== "TT" && country !== "PR" &&
                    <NavDropdown id="navbarScrollingDropdownMarcas"
                      title={t("navbar.categorias")}
                      style={{ textTransform: "uppercase" }}
                      show={showDropdown}
                      drop="end"
                      onClick={() => setShowDropdown(!showDropdown)}
                      onHide={() => setShowDropdown(false)}>
                      <div className="container">
                        <div className={`dropdown`}>
                          {categorias && Array.isArray(categorias) ? (
                            categorias.map((categoria) => (
                              <li
                                className={`dropdown dropend ${hoveredCategories[categoria.Codigo] ? "show" : ""
                                  }`}
                                key={categoria.Codigo}
                              >
                                {categoria.Hijos.length > 0 ? (
                                  <Dropdown
                                    show={hoveredCategories[categoria.Codigo]}
                                    onMouseEnter={() => handleMouseEnter(categoria.Codigo)}
                                    onMouseLeave={() => handleMouseLeave(categoria.Codigo)}
                                  >
                                    <Dropdown.Toggle variant="dark" id="navbarScrollingDropdownMarcas" onClick={() => handleClick(categoria)}>
                                      {categoria.Descripcion.toUpperCase()}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {renderSubcategories(categoria.Hijos)}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : (
                                  <NavDropdown.Item>
                                    <Link
                                      to={`/categorias/${categoria.Codigo}`}
                                      className="dropdown-item-categorias"
                                      onClick={() => setShowDropdown(false)}
                                    >
                                      {categoria.Descripcion.toUpperCase()}
                                    </Link>
                                  </NavDropdown.Item>
                                )}
                              </li>
                            ))
                          ) : (
                            <li>{t("navbar-categorias-no-disponibles")}</li>
                          )}
                        </div>
                      </div>
                    </NavDropdown>
                  }
                </NavDropdown>
              }

              {/*Dropdown de Nosotros */}

              {country !== "JM" && country !== "TT" && country !== "PR" && (
                <NavDropdown
                  title={t("navbar.nosotros")}
                  style={{ textTransform: "uppercase" }}
                  id="navbarScrollingDropdown"
                  onClick={() => setShowDropdown(false)}
                >
                  {navbarData.navbarItems[0].items.map((item, index) => (
                    <NavDropdown.Item key={index}>
                      {item.external ? (
                        <a href={item.to} target="_blank" rel="noopener noreferrer" className="dropdown-item mod">
                          {t(item.label)}
                        </a>
                      ) : (
                        <Link to={item.to} className="dropdown-item mod">
                          {t(item.label)}
                        </Link>
                      )}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              )}

              {/* Dropdown de Links útiles */}
              {country !== "JM" && country !== "TT" && country !== "PR" && (
                <NavDropdown
                  title={t("navbar.links-utiles")}
                  style={{ textTransform: "uppercase" }}
                  id="navbarScrollingDropdown"
                  onClick={() => setShowDropdown(false)}
                >
                  {navbarData.navbarItems[1].items.map((item, index) => (
                    (!item.showArg && !item.showUSA) ||
                      (item.showArg && country === "ARG") ||
                      (item.showUSA && country === "USA") ? (
                      item.url ? (
                        <NavDropdown.Item href={item.url} target="_blank" rel="noopener noreferrer" key={index}>
                          <a href={item.url} className="dropdown-item mod">
                            {t(item.label)}
                          </a>
                        </NavDropdown.Item>
                      ) : (
                        item.to && (
                          <NavDropdown.Item as={Link} to={item.to} key={index}>
                            {t(item.label)}
                          </NavDropdown.Item>
                        )
                      )
                    ) : null
                  ))}

                  {/* Dropdown de Política Anticorrupción */}
                  <NavDropdown
                    drop="end"
                    title={t("navbar.subitem-politica")}
                    style={{ textTransform: "uppercase" }}
                    id="navbarScrollingDropdownMarcas">

                    <NavDropdown.Item href={procPath} target="_blank">
                      {t("navbar.politicas1")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href={codPath} target="_blank">
                      {t("navbar.politicas2")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href={manPath} target="_blank">
                      {t("navbar.politicas3")}
                    </NavDropdown.Item>
                    <NavDropdown.Item>

                      <Link rel="prefetch" to="/formularioDenuncia" className="dropdown-item mod">
                        {t("navbar.politicas4")}
                      </Link>

                    </NavDropdown.Item>

                  </NavDropdown>

                </NavDropdown>)}

              {/* Dropdown de sedes */}
              <NavDropdown
                title={t("navbar.sedes")}
                style={{ textTransform: "uppercase" }}
                id="navbarScrollingDropdown"
              >
                {navbarData.navbarItems[3].items.map((item, index) => (
                  <NavDropdown.Item key={index} href={item.to} target="_blank" className={item.class}>
                    <img src={`${process.env.PUBLIC_URL}${item.image}`} alt={`Bandera ${item.label}`} className="setBanderas" />
                    {item.label}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>

              {/*Dropdown de contacto */}
              <NavDropdown
                title={t("navbar.contacto")}
                style={{ textTransform: "uppercase" }}
                id="navbarScrollingDropdown"
                onClick={() => setShowDropdown(false)}>
                <NavDropdown.Item>
                  {" "}
                  <Link rel="prefetch" to="contacto" className="dropdown-item mod">
                    {t("navbar.contacto1")}
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link rel="prefetch" to="trabajaconnosotros" className="dropdown-item mod">
                    {t("navbar.contacto2")}
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>

              {/* Navlink de OFERTAS */}
              <Nav.Item className="ofertas-navlink-container">
                <Nav.Link
                  style={{ textTransform: "uppercase" }}
                  href={country === "ARG" ? "/openbox" : "/ofertas"}
                  className="ofertas-navlink"
                >
                  {t("outlet")}
                </Nav.Link>
              </Nav.Item>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div >
  );
};

