import axios from 'axios';

const apiProductos = process.env.REACT_APP_API_PRODUCTOS;

export const getProductosPorBusquedaService = async (searchInput, limit, offset = 0) => {
    try {
        const response = await axios.get(`${apiProductos}/info`, {
            params: {
                nombre: searchInput,
                limit,
                offset
            }
        });
        return response.data.articulos;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const getCategoriasService = async () => {
    try {
        const response = await axios.get(`${apiProductos}/categorias`);
        return response.data;
    } catch (err) {
        console.error('Error al obtener los datos:', err);
        throw err;
    }
};

export const getProductosPorSKUService = async (sku) => {
    try {
        const response = await axios.get(`${apiProductos}/busqueda`, {
            params: { sku }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching SKU data:', error);
        throw error;
    }
};

export const getProductosPorSearchInputService = async (busqueda) => {
    try {
        const response = await axios.get(`${apiProductos}/busqueda_new`, {
            params: { busqueda }
        });
        return response.data.articulos;
    } catch (error) {
        console.error('Error fetching SKU data:', error);
        throw error;
    }
};