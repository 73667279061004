import "./indexStyle.css"
import { Column, Container, Galeria, Panel } from "./styles";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Carousel } from 'react-bootstrap'
import Hero from "../Hero/Index";
import Info from "../Info/Info";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ProductoVenta from "../ProductoVenta/ProductoVenta";
import axios from 'axios'
import { authContext } from "../../Context/AuthContext";
import { marcasContext } from "../../Context/MarcasContext";
import { Error } from "../../helpers/Error";
import useObtenerDestacados from "../../../hooks/useFetchDestacados";
import { SpinnerMarcas } from "../../helpers/SpinnerMarcas";
import { useTranslation } from "react-i18next";
import CardRelacionados from "./CardRelacionados"

const apiProductos = process.env.REACT_APP_API_PRODUCTOS

const Productos = () => {
  const location = useLocation();
  const _sku = new URLSearchParams(location.search).get('sku');
  const sku = encodeURIComponent(_sku + location.hash)
  const [marca, setMarca] = useState({})
  const [load, setLoad] = useState(false)
  const { token } = useContext(authContext)
  const { itemVisto, setItemVisto } = useContext(marcasContext)
  const { destacado, error, irADetalles, isNavigating } = useObtenerDestacados();
  const [relacionados, setRelacionados] = useState([])

  const _skuOpenbox = location.pathname.split('/').pop();
  const skuOpenbox = encodeURIComponent(_skuOpenbox);

  const _skuOferta = location.pathname.split('/').pop();
  const skuOferta = encodeURIComponent(_skuOferta);


  useEffect(() => {
    obtenerDatos();
    // obtenerDatosRelacionados()
    setItemVisto({ ...itemVisto, sku })
  }, [sku, skuOpenbox]);

  const obtenerDatos = async () => {
    try {
      setLoad(false);
      const isOpenbox = location.pathname.includes('/openbox');
      const isOferta = location.pathname.includes('/oferta');

      let detalleRes, openboxRes, ofertaRes;

      if (isOpenbox) {
        const skuDecoded = decodeURIComponent(skuOpenbox);
        openboxRes = await axios.get(`${apiProductos}/openbox/${skuDecoded}`, {
          headers: { 'Authorization': 'Bearer ' + token }
        });

        if (openboxRes.data.articulo) {
          setMarca(openboxRes.data.articulo);
        } else {
          console.log('No se encontraron datos de Openbox');
        }
      }
      else if (isOferta) {
        const skuDecoded = decodeURIComponent(skuOferta);
        ofertaRes = await axios.get(`${apiProductos}/oferta/${skuDecoded}`, {
          headers: { 'Authorization': 'Bearer ' + token }
        });
  
        if (ofertaRes.data.articulo) {
          setMarca(ofertaRes.data.articulo);
        } else {
          console.log('No se encontraron datos de Oferta');
        }
  
        if (ofertaRes.data.relacionados) {
          setRelacionados(ofertaRes.data.relacionados);
        }

      } else {
        detalleRes = await axios.get(`${apiProductos}/detalle?sku=${sku}`, {
          headers: { 'Authorization': 'Bearer ' + token }
        });

        if (detalleRes.data) {
          if (detalleRes.data.articulo) {
            setMarca(detalleRes.data.articulo || {});
          } else {
            setMarca(detalleRes.data || {});
          }

          if (detalleRes.data.relacionados) {
            setRelacionados(detalleRes.data.relacionados);
          }
        } else {
          console.log('No se encontraron datos del detalle');
        }
      }

      setLoad(true);
    } catch (err) {
      console.log(err);
      setLoad(true);
    }
  };


  // const obtenerDatos = async () => {
  //   try {
  //     const res = await axios.get(`${apiProductos}/detalle?sku=${sku}`, {
  //       headers: { 'Authorization': 'Bearer ' + token }
  //     });

  //     // if (country === "UY" || country === "PA" || country === "SV") {
  //     if (res.data.articulo) {
  //       setMarca(res.data.articulo || {});
  //     } else {
  //       setMarca(res.data || {});
  //     }

  //     if (res.data.relacionados) {
  //       setRelacionados(res.data.relacionados);
  //     }

  //     setLoad(true);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };



  const imagenesItem = marca.Imagenes?.split(',')
  const array = []
  const [t] = useTranslation("global")
  let img = process.env.PUBLIC_URL + '/assets/sin-imagen.png';

  if (!imagenesItem) {
    array.push(img)
  }
  for (let item in imagenesItem) {
    console.log(imagenesItem[item])
    array.push(imagenesItem[item])
  }

  // Manejo los destacados con el hook

  if (error) return <Error />;
  if (!destacado) return null;
  if (isNavigating) return <SpinnerMarcas />

  // Variable con imagen placeholder para mostrar con el ternario cuando el producto no tiene img cargada

  if (!load) return <SpinnerMarcas text={'Cargando producto'} />
  return (
    <Container>
      <Hero data={marca} />
      <Panel>
        <Column>
          <Galeria>
            <Carousel fade variant="dark" id="carouselDetalles">
              {array.map(item => {
                return (
                  <Carousel.Item key={marca.Alias} className="imgDetalles">
                    <LazyLoadImage
                      className="d-block w-100 "
                      src={marca.Imagenes ? process.env.PUBLIC_URL + "/articulos/" + encodeURIComponent(item) : img}
                      alt='sbox'
                      effect="blur"
                    //className="img_tamano"
                    />
                  </Carousel.Item>
                )
              })}
            </Carousel>
          </Galeria>
          <p className="imagenIlustrativa">{t("producto.imagenes-ilustrativas")}</p>
          <Info data={marca} />
          <div className="relacionados-container">
            <h4 style={{ marginLeft: '2em', color: '#4a5759' }}>
              {t("producto.productos-interes")}:
            </h4>
            {relacionados && relacionados.length > 0 ? (
              <div className="map-destacados">
                {relacionados
                  .sort(() => Math.random() - 0.5) // No quiero que se muestren suiempre los mismos
                  .slice(0, 3) // Limita a 3 elementos
                  .map((dest) => (
                    <div key={dest.Nombre} onClick={() => irADetalles(dest, dest.Alias)}>
                      <CardRelacionados
                        Imagen={dest.Imagen ? process.env.PUBLIC_URL + `/articulos/thumbs/${encodeURIComponent(dest.Imagen?.split(',')[0])}` : img}
                        Description={dest.Nombre}
                      />
                    </div>
                  ))}
              </div>
            ) : (
              <h5 style={{ marginLeft: '3em', marginTop: '2em' }}>
                {t("producto.no-existe-relacionado")}
              </h5>
            )}
          </div>
        </Column>
        <Column>
          <ProductoVenta datos={marca} />
          <div className="relacionados-container-mobile">
            <h4 style={{ marginLeft: '2em', color: '#4a5759' }}>
              {t("producto.productos-interes")}:
            </h4>
            {relacionados && relacionados.length > 0 ? (
              <div className="map-destacados-mobile">
                {relacionados
                  .sort(() => Math.random() - 0.5) // No quiero que siempre se muestren los mismos
                  .slice(0, 3) // Limita a 3 elementos
                  .map((dest) => (
                    <div key={dest.Nombre} onClick={() => irADetalles(dest, dest.Alias)}>
                      <CardRelacionados
                        Imagen={dest.Imagen ? process.env.PUBLIC_URL + `/articulos/thumbs/${encodeURIComponent(dest.Imagen?.split(',')[0])}` : img}
                        Description={dest.Nombre}
                      />
                    </div>
                  ))}
              </div>
            ) : (
              <h5 style={{ marginLeft: '2em', marginTop: '2em' }}>
                {t("producto.no-existe-relacionado")}.
              </h5>
            )}
          </div>

        </Column>
      </Panel>
    </Container>
  );
};

export default Productos;

